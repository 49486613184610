import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['tab', 'content', 'searchInput'];

  static values = {
    checkFormsOnUnload: Boolean
  }

  connect() {
    this._boundClickHandler = this.switchTab.bind(this);

    this.tabTargets.forEach((tab) => {
      tab.addEventListener('click', this._boundClickHandler);
    });
  }

  disconnect() {
    this.tabTargets.forEach((tab) => {
      tab.removeEventListener('click', this._boundClickHandler);
    });
  }

  switchTab(event) {
    if (this.checkFormsOnUnloadValue) {
      this._checkFormsOnUnload(event.currentTarget);

      return false;
    }

    this._switchTabAfterUnloadCheck(event.target);
  }

  _switchTabAfterUnloadCheck(target) {
    if (target.nodeName !== 'LI') {
      target = target.closest('li');
    }

    if (target.classList.contains('disabled')) return;

    this._changeTabStatus();
    this._showTargetContent(target);

    target.classList.add('active');
  }

  _changeTabStatus() {
    this.tabTargets.forEach((tab) => {
      if (tab.classList.contains('active')) {
        tab.classList.remove('active');
        this._forceTabReload(tab);
      }
    });
  }

  _showTargetContent(tabTarget) {
    let contentId = tabTarget.dataset.contentId;

    this.contentTargets.forEach((content) => {
      if (content.id === contentId) {
        content.classList.remove('hidden');
      } else {
        content.classList.add('hidden')
      }
    });
  }

  _forceTabReload(tab) {
    if (this.hasSearchInputTarget && this.searchInputTarget !== '') {
      // Force tab to reload if accessed again
      let contentId = tab.dataset.contentId;
      let contentEl = document.getElementById(contentId);
      contentEl.dataset.contentForceReload = true;
    }
  }

  _checkFormsOnUnload(targetTab) {
    const activeTab = this.tabTargets.filter(tab => {
      return tab.classList.contains('active');
    })[0];

    if (activeTab.dataset.tabsWarningMessageOnUnload) {
      this._verifyForms(targetTab);
    } else {
      this._switchTabAfterUnloadCheck(targetTab);
    }
  }

  async _verifyForms(targetTab) {
    try {
      await this._verify();

      this._switchTabAfterUnloadCheck(targetTab);
    } catch (e) {
      // no-op
    }
  }

  async _verify() {
    const unloadChecker = this.application
      .getControllerForElementAndIdentifier(document.body, "unload-checker");

    return await unloadChecker.verify();
  }
}
