import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["defaultAccessPeriodPicker", 'startAtDatePicker', "endAtDatePicker",
    "invalidDatesError", "saveButton"];

  static values = {
    confirmCancel: String,
    confirmAllow: String,
    confirmMessage: String,
    confirmTitle: String,
    typeConfirmAllow: String,
    typeConfirmMessage: String,
    typeConfirmTitle: String
  };

  switchToIndividualDates() {
    this.startAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-disable'));
    this.endAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-disable'));

    this.startAtDatePickerTarget.dispatchEvent(
      new CustomEvent('promote:datepicker-change', {detail: { value: '' } })
    );

    this.endAtDatePickerTarget.dispatchEvent(
      new CustomEvent('promote:datepicker-change', {detail: { value: '' } })
    );

    this.defaultAccessPeriodPickerTarget.dispatchEvent(
      new CustomEvent('promote:number-picker-enable')
    );

    this._hideInvalidDatesError();
  }

  switchToProgramDates() {
    this.startAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-enable'));
    this.endAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-enable'));

    this.defaultAccessPeriodPickerTarget.dispatchEvent(
      new CustomEvent('promote:number-picker-disable')
    );
  }

  switchToProgramDatesWithWarning(event) {
    if (Rails.confirm(null, event.target, this._confirmationModalDataset())) {
      this.startAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-enable'));
      this.endAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-enable'));

      this.defaultAccessPeriodPickerTarget.dispatchEvent(
        new CustomEvent('promote:number-picker-disable')
      );
    } else {
      event.preventDefault();
    }
  }

  switchToIndividualType() {
    if (!Rails.confirm(null, event.target, this._typeConfirmationModalDataset())) {
      event.preventDefault();
    }
  }

  validateDates() {
    if (this.startAtDatePickerTarget.value && this.endAtDatePickerTarget.value) {
      if (this.startAtDatePickerTarget.value > this.endAtDatePickerTarget.value) {
        this._showInvalidDatesError();
      } else {
        this._hideInvalidDatesError();
      }
    } else {
      this._hideInvalidDatesError();
    }
  }

  _showInvalidDatesError() {
    this.invalidDatesErrorTarget.classList.remove('hidden');
    this.saveButtonTarget.disabled = 'disabled';
  }

  _hideInvalidDatesError() {
    this.invalidDatesErrorTarget.classList.add('hidden');
    this.saveButtonTarget.disabled = '';
  }

  _confirmationModalDataset() {
    return {
      confirmCancel: this.confirmCancelValue,
      confirmAllow: this.confirmAllowValue,
      confirmMessage: this.confirmMessageValue,
      confirmTitle: this.confirmTitleValue
    };
  }

  _typeConfirmationModalDataset() {
    return {
      confirmCancel: this.confirmCancelValue,
      confirmAllow: this.typeConfirmAllowValue,
      confirmMessage: this.typeConfirmMessageValue,
      confirmTitle: this.typeConfirmTitleValue
    };
  }
}
