import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["saveButton", "input"];

  static values = { url: String };

  connect() {
    if (this.hasSaveButtonTarget) {
      this._boundEnableSaveButton = this._enableSaveButton.bind(this);

      this.inputTargets.forEach((input) => {
        input.addEventListener("focus", this._boundEnableSaveButton);
      });
    }
  }

  disconnect() {
    if (this.hasSaveButtonTarget) {
      this.inputTargets.forEach((input) => {
        input.removeEventListener("focus", this._boundEnableSaveButton);
      });
    }
  }

  hideFormContainer() {
    window.history.pushState({}, "", this.urlValue);

    const container = document.getElementById("edit-invitation-template-form");
    container.removeChild(container.lastElementChild);
  }

  _enableSaveButton() {
    this.saveButtonTarget.disabled = false;
  }
}