import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'label',
    'input',
    'file',
    'fileList',
    'fileName',
    'fileSize',
    'fileTemplate',
    'error',
    'warning',
    'limitMessage',
    'sizeMessage',
    'documentIcon',
    'imageIcon',
    'videoIcon',
    'uploadedIcon',
    'removeIcon'
  ]
  static values = {
    highlightStyle: { type: String, default: 'border-purple-300' },
    regularStyle: { type: String, default: 'border-slate-400' },
    errorStyle: { type: String, default: 'border-red-600' },
    warningStyle: { type: String, default: 'border-yellow-500' },
    limit: { type: Number, default: 0 },
    maxSize: { type: Number, default: 0 },
    accept: { type: Array, default: [] }
  }

  connect() {
    this.hasErrors = false;
    this.hasWarnings = false;
    this.form = this.element.closest('form');

    this.labelTarget.addEventListener('drop', this._handleDrop.bind(this));
    this.labelTarget.addEventListener('dragenter', this._handleDragenter.bind(this));
    this.labelTarget.addEventListener('dragleave', this._handleDragleave.bind(this));
    this.labelTarget.addEventListener('dragover', this._ignoreDragOver.bind(this));
    this.inputTarget.addEventListener('invalid', this._setErrorState.bind(this));

    this.form.addEventListener('reset', this._handleFormReset.bind(this));

    if (this.form.dataset.remote === 'true') {
      document.addEventListener('ajax:success', this._handleAsyncSubmitSuccess.bind(this));
    }
  }

  disconnect() {
    this.labelTarget.removeEventListener('drop', this._handleDrop.bind(this));
    this.labelTarget.removeEventListener('dragenter', this._handleDragenter.bind(this));
    this.labelTarget.removeEventListener('dragleave', this._handleDragleave.bind(this));
    this.labelTarget.removeEventListener('dragover', this._ignoreDragOver.bind(this));
    this.inputTarget.removeEventListener('invalid', this._setErrorState.bind(this));
    document.removeEventListener('ajax:success', this._handleAsyncSubmitSuccess.bind(this));

    if (this.form) {
      this.form.removeEventListener('reset', this._handleFormReset.bind(this));
    }
  }

  _handleFormReset() {
    this._clearFileList();
    this._removeErrorState();
    this._removeWarningState();
  }

  handleFileSelected(event) {
    this._clearFileList();

    const validFileCount = this._validateFileLimit(event);
    const validFileSizes = this._validateMaxSize(event);

    if (!validFileCount) this.limitMessageTarget.classList.remove('hidden');
    if (!validFileSizes) this.sizeMessageTarget.classList.remove('hidden');
    if (!validFileCount || !validFileSizes) {
      this.warningTarget.classList.remove('hidden');
      this.labelTarget.classList.add(this.warningStyleValue);
      this.labelTarget.classList.remove(this.regularStyleValue);
      this.hasWarnings = true;

      return;
    }

    if (this.hasErrors) this._removeErrorState();
    if (this.hasWarnings) this._removeWarningState();
    Array.from(this.inputTarget.files).forEach((file) => this._createFileElement(file));
  }

  removeFile(event) {
    const fileElement = event.target.closest('[data-components--inputs-file-target="file"');
    const fileName = fileElement.querySelector('.js-file-name').textContent;
    const newFileList = new DataTransfer();

    this.inputTarget.files = Array.from(this.inputTarget.files).forEach((file) => {
      if (file.name !== fileName) {
        newFileList.items.add(file);
      }
    });

    this.inputTarget.files = newFileList.files;
    fileElement.remove();
  }

  _validateFileLimit(event) {
    const files = Array.from(event.target.files);

    if (this.limitValue === 0) return true;
    if (files.length === 0) return true;

    return files.length <= this.limitValue;
  }

  _validateMaxSize(event) {
    if (this.maxSizeValue === 0) return true;

    return !Array.from(event.target.files).some((file) => file.size > this.maxSizeValue);
  }

  _handleDrop(event) {
    event.stopPropagation();
    event.preventDefault();

    this._removeHighlight();

    const dt = event.dataTransfer;
    const files = dt.files;

    if (this.inputTarget.multiple === false && dt.files.length > 1) return;

    this._clearFileList();

    this.inputTarget.files = files;

    for (let i = 0; i < files.length; i++) {
      this._createFileElement(files[i]);
    }
  }

  _handleDragenter(event) {
    event.stopPropagation();
    event.preventDefault();
    this._addHighlight();
  }

  _handleDragleave(event) {
    event.stopPropagation();
    event.preventDefault();
    this._removeHighlight();
  }

  _ignoreDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  _handleAsyncSubmitSuccess(event) {
    if (event.target.contains(this.inputTarget)) this._clearFileList();
  }

  _setErrorState(event) {
    event.preventDefault();
    this.labelTarget.classList.remove(this.regularStyleValue);
    this.labelTarget.classList.add(this.errorStyleValue);
    this.errorTarget.classList.remove('hidden');
    this.hasErrors = true;
  }

  _removeErrorState() {
    this.labelTarget.classList.add(this.regularStyleValue);
    this.labelTarget.classList.remove(this.errorStyleValue);
    this.errorTarget.classList.add('hidden');
    this.hasErrors = false;
  }

  _removeWarningState() {
    this.labelTarget.classList.add(this.regularStyleValue);
    this.labelTarget.classList.remove(this.warningStyleValue);
    this.warningTarget.classList.add('hidden');
    this.limitMessageTarget.classList.add('hidden');
    this.sizeMessageTarget.classList.add('hidden');
    this.hasWarnings = false;
  }

  _addHighlight() {
    this.labelTarget.classList.add(this.highlightStyleValue);
    this.labelTarget.classList.remove(this.regularStyleValue);
  }

  _removeHighlight() {
    this.labelTarget.classList.add(this.regularStyleValue);
    this.labelTarget.classList.remove(this.highlightStyleValue);
  }

  _createFileElement(file) {
    const temp = document.createElement('div');
    temp.appendChild(this.fileTemplateTarget.content.cloneNode(true));
    temp.querySelector('.js-file-name').textContent = file.name;
    temp.querySelector('.js-file-size').textContent = this._readableFileSize(file.size);
    const fileEl = temp.firstElementChild;

    const fileTypeIconEl = this._iconForFileType(file);
    const secondaryIconEl = this.removeIconTarget.content.cloneNode(true);
    fileEl.querySelector('.js-type-icon-placeholder').replaceWith(fileTypeIconEl);
    fileEl.querySelector('.js-secondary-icon-placeholder').replaceWith(secondaryIconEl);

    this.fileListTarget.appendChild(fileEl);
  }

  _clearFileList() {
    this.fileListTarget.innerHTML = '';
  }

  _readableFileSize(number) {
    if (number < 1e3) {
      return `${number} bytes`;
    } else if (number >= 1e3 && number < 1e6) {
      return `${(number / 1e3).toFixed(1)} KB`;
    } else if (number >= 1e6 && number < 1e9) {
      return `${(number / 1e6).toFixed(1)} MB`;
    } else {
      return `${(number / 1e9).toFixed(1)} GB`;
    }
  }

  _iconForFileType(file) {
    const type = this._typeBasedOnMimeType(file) || this._typeBasedOnFileFormat(file);

    switch (type) {
      case 'image':
        return this.imageIconTarget.content.cloneNode(true);
      case 'video':
        return this.videoIconTarget.content.cloneNode(true);
      default:
        return this.documentIconTarget.content.cloneNode(true);
    }
  }

  _typeBasedOnMimeType(file) {
    const mimeType = file.type;
    const genericType = mimeType.split('/')[0];

    if (genericType === 'image' || genericType === 'video') {
      return genericType;
    }
  }

  _typeBasedOnFileFormat(file) {
    const type = file.name.split('.').pop();

    if (type === 'mov') {
      return 'video';
    }
  }
}
