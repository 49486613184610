import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  static values = {
    userId: Number
  };

  static targets = [
    'sidebar',
    'iconExpanded',
    'iconCollapsed',
    'mobileIcon',
    'logo',
    'menuItem',
    'topNav',
    'content',
    'userMenu',
    'contentContainer'
  ];

  toggle() {
    this._toggleSidebarClasses();
    this._toggleTopNavClasses();
    this._toggleLogoClasses();
    this._toggleIconClasses();
    this._hideItemText();
    this._toggleAvatarPosition();
    this._toggleUserMenu();

    this._updateState();
  }

  toggleOnMobile() {
    this.contentTarget.classList.toggle('hidden');
    this.mobileIconTargets.forEach(icon => {
      icon.classList.toggle('hidden');
    });
  }

  _toggleAvatarPosition() {
    this.userMenuTarget.classList.toggle('sm:items-center');
  }

  _toggleSidebarClasses() {
    ['sm:w-300', 'w-full', 'sm:pt-10'].forEach(style => {
      this.sidebarTarget.classList.toggle(style);
    });

    if (this.sidebarTarget.classList.contains('sm:w-300')) {
      this.contentContainerTarget.classList.add('sm:max-w-[calc(100%_-_300px)]');
      this.contentContainerTarget.classList.remove('sm:max-w-[calc(100%_-_104px)]');
    } else {
      this.contentContainerTarget.classList.add('sm:max-w-[calc(100%_-_104px)]');
      this.contentContainerTarget.classList.remove('sm:max-w-[calc(100%_-_300px)]');
    }
  }

  _toggleTopNavClasses() {
    ['sm:justify-between', 'sm:justify-center'].forEach(style => {
      this.topNavTarget.classList.toggle(style);
    });
  }

  _toggleLogoClasses() {
    this.logoTarget.classList.toggle('sm:hidden');
  }

  _toggleIconClasses() {
    if (this.iconExpandedTarget.classList.contains('sm:flex')) {
      this._hideIcon(this.iconExpandedTarget);
      this._showIcon(this.iconCollapsedTarget);
    } else {
      this._hideIcon(this.iconCollapsedTarget);
      this._showIcon(this.iconExpandedTarget);
    }
  }

  _hideIcon(iconTarget) {
    iconTarget.classList.remove('sm:flex');
    iconTarget.classList.add('sm:hidden');
  }

  _showIcon(iconTarget) {
    iconTarget.classList.remove('sm:hidden');
    iconTarget.classList.add('sm:flex');
  }

  _hideItemText() {
    this.menuItemTargets.forEach(item => {
      item.classList.toggle('sm:hidden');
    })
  }

  _toggleUserMenu() {
    let userMenuController = this.application.controllers.find(controller => {
      return controller.context.identifier === 'sidebar-user-menu';
    });

    userMenuController.toggleUserMenuOnCollapse();
  }

  _updateState() {
    ajax({
      url: `${window.location.origin}/admin/sidebar`,
      type: "PATCH",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    });
  }
}
