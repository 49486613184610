import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = [
    'assignedResourcesHeading',
    'assignedResourcesList',
    'fileField',
    'fileInput',
    'linkField',
    'linkInput',
    'newResourceForm',
    'newResourceSection',
    'resourceAssignmentsSection',
    'duplicateWarningSection',
    'searchForm',
    'searchResults',
    'scormCloudCourseField'
  ];

  static values = {
    assignedToType: String,
    assignedToId: Number,
    programId: String
  };

  connect() {
    // FIXME dropdown component doesn't accept data attributes so we can't add targets
    this.scormCloudCourseInput = this.newResourceFormTarget
      .querySelector('input[name="resource[scorm_cloud_course_id]"]');

    document.addEventListener(
      'promote:editor--resources:created', this.handleResourceCreated.bind(this)
    );

    document.addEventListener(
      'promote:editor--resources:assigned', this.handleResourceAssigned.bind(this)
    );

    document.addEventListener(
      'promote:editor--resources:show-duplicate-warning', this.handleDuplicateSection.bind(this)
    );

    document.addEventListener(
      'promote:editor--resources:no-duplicates', this.closeDuplicateWarning.bind(this));

    this.fileFieldTarget.addEventListener('change', this.verifyFileDuplicate.bind(this));

    this.verifyLinkDuplicateHandler = debounce(this._verifyOnInput, 300).bind(this);
  }

  _verifyOnInput(event) {
    this.newResourceFormTarget
      .querySelector('button[type="submit"]').disabled = true;

    this.verifyLinkDuplicate(event);
  }

  handleDuplicateSection(event) {
    if (this.assignedToTypeValue != event.detail.assignedToType ||
        this.assignedToIdValue != event.detail.assignedToId) {
      return;
    }

    this.newResourceSectionTarget.classList.add('hidden');
    this.duplicateWarningSectionTarget.classList.remove('hidden');
    this.duplicateWarningSectionTarget.children[0].classList.remove('hidden');
  }

  handleResourceCreated(event) {
    if (this.assignedToTypeValue != event.detail.assignedToType ||
        this.assignedToIdValue != event.detail.assignedToId) {
      return;
    }

    this.showResourceAssignmentsSection();
    this.newResourceFormTarget.reset();
    this.assignedResourcesListTarget.insertAdjacentHTML('beforeend', event.detail.element);
    this.assignedResourcesHeadingTarget.classList.remove('hidden');
  }

  handleResourceAssigned(event) {
    if (this.assignedToTypeValue != event.detail.assignedToType ||
        this.assignedToIdValue != event.detail.assignedToId) {
      return;
    }

    this.assignedResourcesListTarget.insertAdjacentHTML('beforeend', event.detail.element);
    this.assignedResourcesHeadingTarget.classList.remove('hidden');

    if (!this.duplicateWarningSectionTarget.classList.contains('hidden')) {
      this.duplicateWarningSectionTarget.classList.add('hidden');

      this.showResourceAssignmentsSection();
      this.newResourceFormTarget.reset();
    }
  }

  closeDuplicateWarning(event) {
    const assignedToType = event?.
      detail?.assignedToType || event.currentTarget.dataset.assignedToType;

    const assignedToId = event?.
      detail?.assignedToId || event.currentTarget.dataset.assignedToId;

    if (this.assignedToTypeValue != assignedToType ||
        this.assignedToIdValue != assignedToId) {
      return;
    }

    this.duplicateWarningSectionTarget.classList.add('hidden');

    this.showNewResourceSection();

    this.newResourceFormTarget
      .querySelector('button[type="submit"]').disabled = false;
  }

  verifyLinkDuplicate(event) {
    this.dispatchDuplicationCheck('link', event.target.value);
  }

  verifyFileDuplicate(event) {
    this.dispatchDuplicationCheck('file', event.target.files[0]);
  }

  dispatchDuplicationCheck(type, data) {
    const formData = new FormData();
    formData.append(type, data);
    formData.append('resource_type', type);
    formData.append('assigned_to_id', this.assignedToIdValue);
    formData.append('assigned_to_type', this.assignedToTypeValue);

    ajax({
      url: `/admin/programs/${this.programIdValue}/editor/resources/verify`,
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      dataType: 'script',
      success: function(response) {
      },
      error: function(response) {
        console.error('Error verifying duplicate resource', response);
      }
    });
  }

  cancelNewResource() {
    this.showResourceAssignmentsSection();
    this.newResourceFormTarget.reset();
  }

  resetForm(event) {
    event.target.closest('form').reset();
  }

  showResourceAssignmentsSection() {
    this.resourceAssignmentsSectionTarget.classList.remove('hidden');
    this.newResourceSectionTarget.classList.add('hidden');
  }

  showNewResourceSection() {
    this.newResourceSectionTarget.classList.remove('hidden');
    this.resourceAssignmentsSectionTarget.classList.add('hidden');
  }

  showLinkField() {
    this.linkFieldTarget.classList.remove('hidden');
    this.fileFieldTarget.classList.add('hidden');
    this.scormCloudCourseFieldTarget.classList.add('hidden');
    this.linkInputTarget.disabled = false;
    this.fileInputTarget.disabled = true;
    this.scormCloudCourseInput.disabled = true;

    this.linkInputTarget.addEventListener('input', this.verifyLinkDuplicateHandler);
  }

  showFileField() {
    this.fileFieldTarget.classList.remove('hidden');
    this.linkFieldTarget.classList.add('hidden');
    this.scormCloudCourseFieldTarget.classList.add('hidden');
    this.fileInputTarget.disabled = false;
    this.linkInputTarget.disabled = true;
    this.scormCloudCourseInput.disabled = true;

    this.linkInputTarget.removeEventListener('input', this.verifyLinkDuplicateHandler);
  }

  showScormCloudCourseField() {
    this.scormCloudCourseFieldTarget.classList.remove('hidden');
    this.linkFieldTarget.classList.add('hidden');
    this.fileFieldTarget.classList.add('hidden');
    this.scormCloudCourseInput.disabled = false;
    this.linkInputTarget.disabled = true;
    this.fileInputTarget.disabled = true;

    this.newResourceFormTarget
      .querySelector('button[type="submit"]').disabled = false;

    this.linkInputTarget.removeEventListener('input', this.verifyLinkDuplicateHandler);
  }
}
